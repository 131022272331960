<!-- eslint-disable vue/max-attributes-per-line -->
<template>
  <div>
    <validation-observer ref="loginValidation">
      <b-form
        class="auth-login-form mt-2"
        @submit.prevent
        enctype="multipart/form-data"
      >
        <div class="row">
          <!-- $store.state.base_url + 'images/CIT-13-Years.jpg' -->
          <div class="col-md-9">
            <div class="row">
              <div class="col-md-6">
                <!-- email -->
                <b-form-group label="Phone Number" label-for="phone_number">
                  <validation-provider
                    #default="{ errors }"
                    name="phone_number"
                    rules="required"
                  >
                    <b-form-input
                      id="phone_number"
                      v-model="form.phone_number"
                      :state="errors.length > 0 ? false : null"
                      name="phone_number"
                      placeholder="Enter Your Phone Number"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </div>
              <div class="col-md-6">
                <!-- email -->
                <b-form-group label="Email" label-for="email">
                  <validation-provider
                    #default="{ errors }"
                    name="email"
                    rules="required"
                  >
                    <b-form-input
                      id="email"
                      v-model="form.email"
                      :state="errors.length > 0 ? false : null"
                      name="email"
                      placeholder="Enter Your Email"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </div>
              <div class="col-md-12">
                <!-- email -->
                <b-form-group label="Virtual Tour" label-for="virtual_tour">
                  <validation-provider
                    #default="{ errors }"
                    name="virtual_tour"
                    rules="required"
                  >
                    <b-form-textarea
                      id="virtual_tour"
                      v-model="form.virtual_tour"
                      :state="errors.length > 0 ? false : null"
                      name="virtual_tour"
                      placeholder="Enter Your Virtual Tour"
                      rows="4"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="border rounded p-2 mb-2">
              <b-media-aside>
                <b-img
                  :src="logo"
                  height="80"
                  class="rounded mr-2 mb-1 mb-md-0"
                />
              </b-media-aside>
              <b-media-body>
                <b-form-group label="Logo" label-for="logo" class="mt-1">
                  <b-form-file
                    id="logo"
                    accept=".jpg, .png, .gif"
                    @change="loadImage($event)"
                    placeholder="Choose a file or drop it here..."
                    drop-placeholder="Drop file here..."
                  />
                </b-form-group>
              </b-media-body>
            </div>
          </div>
        </div>
        <!-- submit buttons -->
        <b-button type="submit" variant="primary" @click="validationForm">
          Update
        </b-button>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import {
  BMediaBody,
  BMediaAside,
  BMedia,
  BFormFile,
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BFormTextarea,
} from "bootstrap-vue";
import { required, email } from "@validations";
import { ValidationProvider, ValidationObserver } from "vee-validate";
export default {
  data() {
    return {
      form: {
        phone_number: "",
        email: "",
        virtual_tour: "",
        logo: null,
      },
      logo: null,
      required,
      email,
    };
  },
  mounted() {
    this.getBanner();
  },
  methods: {
    async getBanner() {
      const header = await this.callApi("get", "/app/header/show");
      if (header.status == 200) {
        this.form.phone_number = header.data.header.phone_number;
        this.form.email = header.data.header.email;
        this.form.virtual_tour = header.data.header.virtual_tour;
        this.logo =
          this.$store.state.base_url +
          (header.data.header.logo
            ? header.data.header.logo
            : "images/logo/default.png");
      }
    },

    loadImage(e) {
      this.form.logo = e.target.files[0];
      let file = e.target.files[0];
      let reader = new FileReader();
      reader.onload = (e) => {
        this.logo = e.target.result;
      };
      reader.readAsDataURL(file);
    },

    Update() {
      const fd = new FormData();
      if (this.form.logo) {
        fd.append("logo", this.form.logo, this.form.logo.name);
      }
      fd.append("phone_number", this.form.phone_number);
      fd.append("email", this.form.email);
      fd.append("virtual_tour", this.form.virtual_tour);

      axios
        .post("/app/header/update", fd)
        .then((res) => {
          this.getBanner();
          this.s(res.data.message);
        })
        .catch((e) => {
          if (e.response.status === 422) {
            if (e.response.data.errors.logo)
              this.e(e.response.data.errors.logo[0]);
          }
        });
    },
    validationForm() {
      this.$refs.loginValidation.validate().then((success) => {
        if (success) {
          this.Update();
        }
      });
    },
  },
  components: {
    BMedia,
    BFormFile,
    BFormTextarea,
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BMediaAside,
    BMediaBody,
  },
};
</script>

<style></style>
