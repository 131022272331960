<!-- eslint-disable vue/html-closing-bracket-newline -->
<template>
  <div>
    <b-card>
      <b-tabs content-class="mt-2" justified>
        <b-tab active title="Header">
          <b-card-text>
            <Header />
          </b-card-text>
        </b-tab>
        <b-tab title="Footer">
          <b-card-text>
            <Footer />
          </b-card-text>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import { BTabs, BTab, BCard, BCardText, BLink } from "bootstrap-vue";
import Header from "./partials/Header.vue";
import Footer from "./partials/Footer.vue";
export default {
  components: {
    Header,
    Footer,
    BCard,
    BCardText,
    BLink,
    BTabs,
    BTab,
  },
};
</script>

<style></style>
