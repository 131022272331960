<!-- eslint-disable vue/max-attributes-per-line -->
<template>
  <div>
    <validation-observer ref="loginValidation">
      <b-form
        class="auth-login-form mt-2"
        @submit.prevent
        enctype="multipart/form-data"
      >
        <div class="row">
          <div class="col-md-6">
            <b-form-group label="Footer Heading" label-for="footer_heading">
              <validation-provider
                #default="{ errors }"
                name="footer_heading"
                rules="required"
              >
                <b-form-input
                  id="footer_heading"
                  v-model="form.footer_heading"
                  :state="errors.length > 0 ? false : null"
                  name="footer_heading"
                  placeholder="Enter Your Footer Heading"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="Footer Description"
              label-for="footer_description"
            >
              <validation-provider
                #default="{ errors }"
                name="footer_description"
                rules="required"
              >
                <b-form-textarea
                  id="footer_description"
                  v-model="form.footer_description"
                  :state="errors.length > 0 ? false : null"
                  name="footer_description"
                  placeholder="Enter Your Footer Description"
                  rows="4"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </div>
          <div class="col-md-6">
            <div class="row">
              <div class="col-md-5">
                <b-form-group label="Button Name One" label-for="btn_one">
                  <validation-provider
                    #default="{ errors }"
                    name="btn_one"
                    rules="required"
                  >
                    <b-form-input
                      id="btn_one"
                      v-model="form.btn_one"
                      :state="errors.length > 0 ? false : null"
                      name="btn_one"
                      placeholder="Enter Your Button Name"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </div>
              <div class="col-md-7">
                <b-form-group label="Button Link One" label-for="btn_one_link">
                  <validation-provider
                    #default="{ errors }"
                    name="btn_one_link"
                    rules="required"
                  >
                    <b-form-input
                      id="btn_one_link"
                      v-model="form.btn_one_link"
                      :state="errors.length > 0 ? false : null"
                      name="btn_one_link"
                      placeholder="Enter Your Button Link"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </div>
            </div>
            <div class="row">
              <div class="col-md-5">
                <b-form-group label="Button Name Two" label-for="btn_two">
                  <validation-provider
                    #default="{ errors }"
                    name="btn_two"
                    rules="required"
                  >
                    <b-form-input
                      id="btn_two"
                      v-model="form.btn_two"
                      :state="errors.length > 0 ? false : null"
                      name="btn_two"
                      placeholder="Enter Your Button Name"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </div>
              <div class="col-md-7">
                <b-form-group label="Button Link Two" label-for="btn_two_link">
                  <validation-provider
                    #default="{ errors }"
                    name="btn_two_link"
                    rules="required"
                  >
                    <b-form-input
                      id="btn_two_link"
                      v-model="form.btn_two_link"
                      :state="errors.length > 0 ? false : null"
                      name="btn_two_link"
                      placeholder="Enter Your Button Link"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </div>
            </div>
            <b-form-group label="Copyright Text" label-for="copyright_text">
              <validation-provider
                #default="{ errors }"
                name="copyright_text"
                rules="required"
              >
                <b-form-input
                  id="copyright_text"
                  v-model="form.copyright_text"
                  :state="errors.length > 0 ? false : null"
                  name="copyright_text"
                  placeholder="Enter Your Copyright Text"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </div>
          <div class="col-md-4">
            <b-form-group label="Address" label-for="address">
              <validation-provider
                #default="{ errors }"
                name="address"
                rules="required"
              >
                <b-form-textarea
                  id="address"
                  v-model="form.address"
                  :state="errors.length > 0 ? false : null"
                  name="address"
                  placeholder="Enter Your Address"
                  rows="4"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </div>
          <div class="col-md-4">
            <b-form-group label="Phone Number" label-for="phone_number">
              <validation-provider
                #default="{ errors }"
                name="phone_number"
                rules="required"
              >
                <b-form-textarea
                  id="phone_number"
                  v-model="form.phone_number"
                  :state="errors.length > 0 ? false : null"
                  name="phone_number"
                  placeholder="Enter Your Phone Number"
                  rows="4"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </div>
          <div class="col-md-4">
            <b-form-group label="Email" label-for="email">
              <validation-provider
                #default="{ errors }"
                name="email"
                rules="required"
              >
                <b-form-textarea
                  id="email"
                  v-model="form.email"
                  :state="errors.length > 0 ? false : null"
                  name="email"
                  placeholder="Enter Your Email"
                  rows="4"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </div>
          <div class="col-md-3">
            <b-form-group label="Facebook URL" label-for="facebook_url">
              <validation-provider
                #default="{ errors }"
                name="facebook_url"
                rules="required"
              >
                <b-form-input
                  id="facebook_url"
                  v-model="form.facebook_url"
                  :state="errors.length > 0 ? false : null"
                  name="facebook_url"
                  placeholder="Enter Your Facebook URL"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </div>
          <div class="col-md-3">
            <b-form-group label="Linkedin URL" label-for="linkedin_url">
              <validation-provider
                #default="{ errors }"
                name="linkedin_url"
                rules="required"
              >
                <b-form-input
                  id="linkedin_url"
                  v-model="form.linkedin_url"
                  :state="errors.length > 0 ? false : null"
                  name="linkedin_url"
                  placeholder="Enter Your Linkedin URL"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </div>
          <div class="col-md-3">
            <b-form-group label="Youtube URL" label-for="youtube_url">
              <validation-provider
                #default="{ errors }"
                name="youtube_url"
                rules="required"
              >
                <b-form-input
                  id="youtube_url"
                  v-model="form.youtube_url"
                  :state="errors.length > 0 ? false : null"
                  name="youtube_url"
                  placeholder="Enter Your Youtube URL"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </div>
          <div class="col-md-3">
            <b-form-group label="Instagram URL" label-for="instagram_url">
              <validation-provider
                #default="{ errors }"
                name="instagram_url"
                rules="required"
              >
                <b-form-input
                  id="instagram_url"
                  v-model="form.instagram_url"
                  :state="errors.length > 0 ? false : null"
                  name="instagram_url"
                  placeholder="Enter Your Instagram URL"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </div>
        </div>

        <!-- submit buttons -->
        <b-button type="submit" variant="primary" @click="validationForm">
          Update
        </b-button>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import {
  BMediaBody,
  BMediaAside,
  BMedia,
  BFormFile,
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BFormTextarea,
} from "bootstrap-vue";
import { required, email } from "@validations";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";
export default {
  data() {
    return {
      form: {
        footer_heading: "",
        footer_description: "",
        btn_one: "",
        btn_one_link: "",
        btn_two: "",
        btn_two_link: "",
        copyright_text: "",
        address: "",
        phone_number: "",
        email: "",
        facebook_url: "",
        linkedin_url: "",
        youtube_url: "",
        instagram_url: "",
      },
      required,
      email,
    };
  },
  mounted() {
    this.getCoursePage();
  },
  methods: {
    async getCoursePage() {
      const footer = await this.callApi("get", "/app/footer/show");
      if (footer.status == 200) {
        this.form.footer_heading = footer.data.footer.footer_heading;
        this.form.footer_description = footer.data.footer.footer_description;
        this.form.btn_one = footer.data.footer.btn_one;
        this.form.btn_one_link = footer.data.footer.btn_one_link;
        this.form.btn_two = footer.data.footer.btn_two;
        this.form.btn_two_link = footer.data.footer.btn_two_link;
        this.form.copyright_text = footer.data.footer.copyright_text;
        this.form.address = footer.data.footer.address;
        this.form.phone_number = footer.data.footer.phone_number;
        this.form.email = footer.data.footer.email;
        this.form.facebook_url = footer.data.footer.facebook_url;
        this.form.linkedin_url = footer.data.footer.linkedin_url;
        this.form.youtube_url = footer.data.footer.youtube_url;
        this.form.instagram_url = footer.data.footer.instagram_url;
      }
    },
    Update() {
      axios
        .post("/app/footer/update", this.form)
        .then((res) => {
          this.getCoursePage();
          this.s(res.data.message);
        })
        .catch((e) => {
          if (e.response.status === 422) {
            // if (e.response.data.errors.title)
            //   this.e(e.response.data.errors.title[0]);
          }
        });
    },
    validationForm() {
      this.$refs.loginValidation.validate().then((success) => {
        if (success) {
          this.Update();
        }
      });
    },
  },
  computed: {},
  components: {
    vSelect,
    BMedia,
    BFormFile,
    BFormTextarea,
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BMediaAside,
    BMediaBody,
  },
};
</script>

<style></style>
